// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-early-booking-js": () => import("./../../../src/pages/early-booking.js" /* webpackChunkName: "component---src-pages-early-booking-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-explore-js": () => import("./../../../src/pages/explore.js" /* webpackChunkName: "component---src-pages-explore-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-spa-js": () => import("./../../../src/pages/spa.js" /* webpackChunkName: "component---src-pages-spa-js" */),
  "component---src-pages-suites-index-js": () => import("./../../../src/pages/suites/index.js" /* webpackChunkName: "component---src-pages-suites-index-js" */),
  "component---src-pages-suites-junior-suite-js": () => import("./../../../src/pages/suites/junior-suite.js" /* webpackChunkName: "component---src-pages-suites-junior-suite-js" */),
  "component---src-pages-suites-master-suite-js": () => import("./../../../src/pages/suites/master-suite.js" /* webpackChunkName: "component---src-pages-suites-master-suite-js" */),
  "component---src-pages-suites-standard-double-js": () => import("./../../../src/pages/suites/standard-double.js" /* webpackChunkName: "component---src-pages-suites-standard-double-js" */),
  "component---src-pages-suites-standard-js": () => import("./../../../src/pages/suites/standard.js" /* webpackChunkName: "component---src-pages-suites-standard-js" */),
  "component---src-pages-weddings-js": () => import("./../../../src/pages/weddings.js" /* webpackChunkName: "component---src-pages-weddings-js" */)
}

